import React from "react"
import Navlinks from '../components/nav';
import { graphql, Link } from "gatsby";
import { css } from "@emotion/core";
import Icon from '@material-ui/core/Icon';
import Layout from "../components/layout.js";
import ClientSearch from "../components/ClientSearch"
const SearchTemplate = props => {
  const { pageContext } = props
  const { searchData } = pageContext
  const { allPages, allFiles, options } = searchData
  const allSearch = allPages.concat(allFiles);
  return (
    <Layout>
    <header className="mainHead flexContainer faCenter fjCenter">      
        <div className="fCol12 menuCtrl">
          <Link className="headerTitle" to="/">
            <span className="arrowbk"><Icon>arrow_back</Icon></span><span className="mobileShow">Back</span><span className="mobileHide" dangerouslySetInnerHTML={{ __html: "Home"}}/>
          </Link>
          
          {/* Nav Side */}
          <Navlinks />
        </div>
    </header>
    <div id="tabsContainer" className="tabsContainer" css={css`margin-top:5vh`}>
      <section id="content-home" className="active">
          {/*{console.log(allSearch)}*/}
          {/*{console.log(options)}*/}
          <ClientSearch items={allSearch} engine={options} />
      </section>
    </div>
    </Layout>
  )
}
export default SearchTemplate